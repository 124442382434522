import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import JobSearch from "@sourceflow-uk/job-search";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import styles from './styles.module.scss';
import Link from 'next/link';

export default function MainBanner({
    global,
    pathPrefix,
    customClass = "",
    link1,
    link2,
    heading1,
    heading2,
    subtitle1,
    subtitle2,
    imageSrc1,
    imageSrc2
}) {
    return (
        <>
            <section className={`${styles.root} ${customClass}`} >
                <Container>
                    <Row>
                        <Col md={6} className="left text-center mb-4 mb-md-0">
                            <div className="rounded-3 overflow-hidden shadow position-relative">
                                <Link href={link1}>
                                    <a className="text-decoration-none d-block ps-4 pt-5 pe-4 position-relative">
                                        <div className="position-relative content-wrapper">
                                            <h2 className="fs-1">
                                                <SourceFlowText global={global} path={`${pathPrefix}.heading1`}>{heading1}</SourceFlowText>
                                            </h2>
                                            <p className="fs-6 subtitle position-relative d-inline-block pe-5 text-white">
                                                <SourceFlowText global={global} path={`${pathPrefix}.subtitle1`}>{subtitle1}</SourceFlowText>
                                            </p>
                                        </div>
                                        <SourceFlowImage
                                            src={imageSrc1}
                                            size="1000x1500"
                                            alt="Job Seeker Image"
                                            className="card-img rounded-0 position-absolute"
                                            width="100%"
                                            height="100%"
                                            loading="eager"
                                        />
                                    </a>
                                </Link>
                                <JobSearch
                                    options={{
                                        searchResults: false,
                                        searchFilters: false,
                                        submitDestination: "/jobs/",
                                    }}
                                    translations={{
                                        "input.placeHolder": "Search jobs by title or location...",
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={6} className="right text-center">
                            <div className="rounded-3 overflow-hidden shadow">
                                <Link href={link2}>
                                    <a className="text-decoration-none d-block ps-4 pt-5 pe-4 position-relative">
                                        <div className="position-relative content-wrapper">
                                            <h2 className="fs-1">
                                                <SourceFlowText global={global} path={`${pathPrefix}.heading2`}>{heading2}</SourceFlowText>
                                            </h2>
                                            <p className="fs-6 subtitle position-relative d-inline-block pe-5 text-white">
                                                <SourceFlowText global={global} path={`${pathPrefix}.subtitle2`}>{subtitle2}</SourceFlowText>
                                            </p>
                                        </div>
                                        <SourceFlowImage
                                            src={imageSrc2}
                                            size="1000x1500"
                                            alt="Employers Image"
                                            className="card-img rounded-0 position-absolute"
                                            width="100%"
                                            height="100%"
                                            loading="eager"
                                        />
                                    </a>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}