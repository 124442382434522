import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Card,
    Col,
    Row
} from "reactstrap";
import React, { useState } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';

export default function AllPartners({
    global,
    pathPrefix,
    heading,
    customClass = "",
    post,
}) {

    return (
        post?.length >= 1 && (
            <section className={`${styles.root} ${customClass}`} >
                <Container>
                    {heading ?
                        <h3>
                            <SourceFlowText global={global} path={`${pathPrefix}.heading`}>
                                {heading}
                            </SourceFlowText>
                        </h3> : ""}
                    <Row className="align-items-center mt-5">
                        {post.map((post, index) =>
                            <Col xs={6} md={3} className="mb-4 mb-md-0" key={index}>
                                <Card>
                                    <Link href={`${post.link}`}>
                                        <a target="_blank" rel="noopener noreferrer" className={`${post?.url_slug}`}>
                                            <SourceFlowImage
                                                src={post?.image}
                                                alt={post?.title}
                                                size="250x"
                                                className={`card-img rounded-0 m-auto`}
                                                width={250}
                                                height={50}
                                            />
                                        </a>
                                    </Link>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
        )
    )
}