import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card
} from "reactstrap";
import styles from './styles.module.scss';
import Link from 'next/link';

export default function LatestNews({
    global,
    pathPrefix,
    customClass = "",
    heading,
    all_news,
    buttonTitle,
    removeIcons,
    pagePrefix
}) {
    return (
        all_news.length >= 1 && (
            <section className={`${styles.root} ${customClass} overflow-hidden`} >
                <Container>
                    <h2 className="mb-4 heading ps-md-5">
                        <SourceFlowText global={global} path={`${pathPrefix}.heading`}>{heading}</SourceFlowText>
                    </h2>
                    <Row>
                        <Col md={6} className="left">
                            {all_news?.map((post, index) => (
                                <div key={index}>
                                    {index == 0 ?
                                        <div className="position-relative">
                                            <Link href={`/${pagePrefix ? pagePrefix : (post?.tag).replaceAll("Jobseeker", "job-seekers-news").replaceAll("Employer", "employers-news")}/${post.url_slug}`}>
                                                <a className="text-decoration-none d-block">
                                                    <Card className="rounded-3 overflow-hidden ps-4 pb-4 pe-4 shadow" key={index}>
                                                        <SourceFlowImage
                                                            src={post?.image ? post.image : "/site-assets/images/image-placeholder.webp"}
                                                            size="650x600"
                                                            alt={post?.title}
                                                            className="card-img position-absolute"
                                                            width="100%"
                                                            height="100%"
                                                        />
                                                        <div className="position-relative">
                                                            <div className="post-title h3">{post?.title}</div>
                                                            <div className="mt-4">
                                                                <span className="primaryBtn">
                                                                    <SourceFlowText global={global} path={`${pathPrefix}.buttonTitle`}>
                                                                        {buttonTitle}
                                                                    </SourceFlowText>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card></a></Link>
                                            {removeIcons ?
                                                "" : <span className="circle position-absolute d-none d-lg-block"></span>
                                            }
                                        </div>
                                        : ""}
                                </div>
                            ))}
                        </Col>
                        <Col md={6} className="right mt-4 mt-md-0 position-relative">
                            {removeIcons ?
                                "" : <span className="circle position-absolute d-none d-lg-block"></span>
                            }                            {all_news?.map((post, index) => (
                                <div className={`item-${index}`} key={index}>
                                    {index >= 1 && index <= 2 ?
                                        <Link href={`/${pagePrefix ? pagePrefix : (post?.tag).replaceAll("Jobseeker", "job-seekers-news").replaceAll("Employer", "employers-news")}/${post.url_slug}`}>
                                            <a className="text-decoration-none d-block">
                                                <Card className="rounded-3 overflow-hidden ps-4 pb-4 pe-4 mb-4 shadow">
                                                    <SourceFlowImage
                                                        src={post?.image ? post.image : "/site-assets/images/image-placeholder.webp"}
                                                        size="650x650"
                                                        alt={post?.title}
                                                        className="card-img position-absolute"
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                    <div className="position-relative">
                                                        <div className="post-title h5">{post?.title}</div>
                                                        <div className="mt-4">
                                                            <span className="primaryBtn">
                                                                <SourceFlowText global={global} path={`${pathPrefix}.buttonTitle`}>
                                                                    {buttonTitle}
                                                                </SourceFlowText>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Card></a></Link> : ""}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    )
}